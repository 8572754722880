/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect ,useState} from "react";
import { headContent } from "../../Content/HeaderContent";
import { useActiveLink } from './ActiveLinkContext';
import { Link } from 'react-router-dom';


function Header() {
    const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleSubMenu = (menu) => setActiveSubMenu(activeSubMenu === menu ? null : menu);


    const testingServices = [
        "mobile-application-testing",
        "test-automation-services",
        "manual-testing-services",
        "api-testing-services",
        "load-and-performance-testing",
        "compatibility-testing-services",
        "security-testing-services",
        "unit-testing-services",
        "cross-browser-testing-services",
    ];

    const testingCompany = [
        "projects",
        "reviews",
        "about",
    ];

    const highlightLink = 'current_page_item';
    const { activeLink, updateActiveLink } = useActiveLink();
    const setSelectedMenuItem = (link) => {
        updateActiveLink(link);
    };


    useEffect(() => {
        const handleLoad = (e) => {
            const pathName = window.location.pathname.replace('/', '');
            if (testingCompany.includes(pathName)) {
                setSelectedMenuItem('Company');
            };
            if (testingServices.includes(pathName)) {
                { setSelectedMenuItem('Services'); scrollToTop() };
            };
            if (pathName === 'blog') {
                setSelectedMenuItem('Blog')
            }
            if (pathName === 'qa-consultation') {
                setSelectedMenuItem('Consult');
            } if (pathName === 'contact') {
                setSelectedMenuItem('Contact')
            }
            if (pathName === '') {
                setSelectedMenuItem('Home')
            }


        };

        // Add an event listener to the window's load event
        window.addEventListener('load', handleLoad);

        return () => {
            // Remove the event listener when the component is unmounted
            window.removeEventListener('load', handleLoad);
        };
    }, [])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }


    const { websiteName, menubar } = headContent;
    return (<>



        <header className="header">
            <div className="container">
                <Link
                    to="/"
                    title="Missco - Software Testing Company"
                    className="logo"
                    onClick={() => { setSelectedMenuItem('Home'); scrollToTop() }}>

                    {/* <img src={miiscoLogo} alt="logo" width="182px" height="80px"/> */}
                    <h1
                        className="miisco-logo">
                        {websiteName}</h1>
                </Link>
                <div className="hamburger" onClick={toggleMenu}>
                    <span className="line"></span>
                    <span className="line"></span>
                    <span className="line"></span>
                </div>
                <nav className="main-navigation">
                    <ul>
                        <li className={`${activeLink === 'Home' ? highlightLink : ''}`}>
                            <Link
                                to="/"
                                title="Home"
                                onClick={() => { setSelectedMenuItem('Home'); scrollToTop() }}>
                                {menubar.home}</Link></li>

                        <li> <Link style={{ fontWeight: 'bold', color: 'rgb(0,0,0.5)' }}
                            to="/products"
                            title="Products">
                            Products*
                        </Link></li>

                        <li className={`has-sub ${activeLink === 'Company' ? highlightLink : ''}`}>
                            <Link to="#" title="Company">Company</Link>

                            <ul className="is-sub sub-menu">
                                <li>
                                    <Link
                                        to="/about"
                                        title="About Us"
                                        onClick={() => { setSelectedMenuItem('Company'); scrollToTop() }}>
                                        {menubar.company.about}</Link></li>
                                <li>
                                    <Link
                                        to="/reviews"
                                        title="Testimonial"
                                        onClick={() => { setSelectedMenuItem('Company'); scrollToTop() }}>
                                        {menubar.company.testmonial}</Link></li>
                                <li>
                                    <Link
                                        to="/projects"
                                        title="Projects"

                                        onClick={() => { setSelectedMenuItem('Company'); scrollToTop() }}>
                                        {menubar.company.project}</Link></li>
                                <li>
                                    <Link
                                        to="/core-team"
                                        title="Core Team"
                                        onClick={() => { setSelectedMenuItem('Company'); scrollToTop() }}>
                                        {menubar.company.coreTeam}</Link></li>
                            </ul>
                        </li>
                        <li className={`has-sub ${activeLink === 'Services' ? highlightLink : ''}`}>
                            <Link to="#" title="Services">Services</Link>

                            {/* ----------------- Remove Comment code for Development menu ---------------------------------- */}

                            {/* <ul className="is-sub sub-menu-develop"> */}
                            <ul className="is-sub ">
                                <div className="sub-menu services">
                                    <div className="service-menu">
                                        <div className="left-menu">
                                            <li><h4>Development</h4></li>

                                            <li>
                                                <Link
                                                    onClick={() => { setSelectedMenuItem('Services'); scrollToTop() }}
                                                    to="/web-application-development"
                                                    title="Mobile Application Testing">
                                                    Website Development</Link></li>
                                            {/* <span className="danger-link-not-working">not working</span> */}
                                            <li>
                                                <Link
                                                    onClick={() => { setSelectedMenuItem('Services'); scrollToTop() }}
                                                    to="/mobile-application-development"
                                                    title="Test Automation Services">
                                                    App Development</Link></li>
                                            {/* <span className="danger-link-not-working">not working</span> */}

                                        </div>

                                        <div className="right-menu">
                                            <li><h4>QA</h4></li>

                                            <li>
                                                <Link
                                                    onClick={() => { setSelectedMenuItem('Services'); scrollToTop() }}
                                                    to="/mobile-application-testing"
                                                    title="Mobile Application Testing">
                                                    {menubar.services.mobile}</Link></li>
                                            <li>
                                                <Link
                                                    onClick={() => { setSelectedMenuItem('Services'); scrollToTop() }}
                                                    to="/test-automation-services"
                                                    title="Test Automation Services">
                                                    {menubar.services.automation}</Link></li>
                                            <li>
                                                <Link
                                                    onClick={() => { setSelectedMenuItem('Services'); scrollToTop() }}
                                                    to="/manual-testing-services"
                                                    title="Manual Testing Services">
                                                    {menubar.services.manual}</Link></li>
                                            <li>
                                                <Link
                                                    onClick={() => { setSelectedMenuItem('Services'); scrollToTop() }}
                                                    to="/api-testing-services"
                                                    title="API Testing Services">
                                                    {menubar.services.api}</Link></li>
                                            <li>
                                                <Link
                                                    onClick={() => { setSelectedMenuItem('Services'); scrollToTop() }}
                                                    to="/load-and-performance-testing"
                                                    title="Load and Performance Testing">
                                                    {menubar.services.load}</Link></li>
                                            <li>
                                                <Link
                                                    onClick={() => { setSelectedMenuItem('Services'); scrollToTop() }}
                                                    to="/security-testing-services"
                                                    title="Security Testing Services">
                                                    {menubar.services.security}</Link></li>
                                            <li>
                                                <Link
                                                    onClick={() => { setSelectedMenuItem('Services'); scrollToTop() }}
                                                    to="/compatibility-testing-services"
                                                    title="Compatibility Testing Services">
                                                    {menubar.services.compatiblity}</Link></li>
                                            <li>
                                                <Link
                                                    onClick={() => { setSelectedMenuItem('Services'); scrollToTop() }}
                                                    to="/unit-testing-services"
                                                    title="Unit Test Service">{menubar.services.unit}
                                                </Link></li>
                                            <li>
                                                <Link
                                                    onClick={() => { setSelectedMenuItem('Services'); scrollToTop() }}
                                                    to="/cross-browser-testing-services"
                                                    title="Cross Browser Testing Service">
                                                    {menubar.services.crosBrowser}</Link></li>
                                            {/* <li>
                                                <Link
                                                    onClick={() => { setSelectedMenuItem('Services'); scrollToTop() }}
                                                    to="/mobile-application-development"
                                                    title="Cross Browser Testing Service">
                                                    {menubar.services.appDevelopement}</Link></li>
                                            <li>
                                                <Link
                                                    onClick={() => { setSelectedMenuItem('Services'); scrollToTop() }}
                                                    to="/web-application-development"
                                                    title="Cross Browser Testing Service">
                                                    {menubar.services.webDevelopement}</Link></li> */}
                                        </div>
                                    </div>
                                </div>

                                {/* <li>
                                    <Link
                                        onClick={() => { setSelectedMenuItem('Services'); scrollToTop() }}
                                        to="/mobile-application-testing"
                                        title="Mobile Application Testing">
                                        {menubar.services.mobile}</Link></li>
                                <li>
                                    <Link
                                        onClick={() => { setSelectedMenuItem('Services'); scrollToTop() }}
                                        to="/test-automation-services"
                                        title="Test Automation Services">
                                        {menubar.services.automation}</Link></li>
                                <li>
                                    <Link
                                        onClick={() => { setSelectedMenuItem('Services'); scrollToTop() }}
                                        to="/manual-testing-services"
                                        title="Manual Testing Services">
                                        {menubar.services.manual}</Link></li>
                                <li>
                                    <Link
                                        onClick={() => { setSelectedMenuItem('Services'); scrollToTop() }}
                                        to="/api-testing-services"
                                        title="API Testing Services">
                                        {menubar.services.api}</Link></li>
                                <li>
                                    <Link
                                        onClick={() => { setSelectedMenuItem('Services'); scrollToTop() }}
                                        to="/load-and-performance-testing"
                                        title="Load and Performance Testing">
                                        {menubar.services.load}</Link></li>
                                <li>
                                    <Link
                                        onClick={() => { setSelectedMenuItem('Services'); scrollToTop() }}
                                        to="/security-testing-services"
                                        title="Security Testing Services">
                                        {menubar.services.security}</Link></li>
                                <li>
                                    <Link
                                        onClick={() => { setSelectedMenuItem('Services'); scrollToTop() }}
                                        to="/compatibility-testing-services"
                                        title="Compatibility Testing Services">
                                        {menubar.services.compatiblity}</Link></li>
                                <li>
                                    <Link
                                        onClick={() => { setSelectedMenuItem('Services'); scrollToTop() }}
                                        to="/unit-testing-services"
                                        title="Unit Test Service">{menubar.services.unit}
                                    </Link></li>
                                <li>
                                    <Link
                                        onClick={() => { setSelectedMenuItem('Services'); scrollToTop() }}
                                        to="/cross-browser-testing-services"
                                        title="Cross Browser Testing Service">
                                        {menubar.services.crosBrowser}</Link></li> */}
                            </ul>
                        </li>
                        <li className={`${activeLink === 'Consult' ? highlightLink : ''}`}>
                            <Link
                                to="/qa-consultation"
                                title="QA Consultation"
                                onClick={() => { setSelectedMenuItem('Consult'); scrollToTop() }}>
                                {menubar.qaConsultation}</Link></li>
                        {/* <li className={`${activeLink === 'Blog' ? highlightLink : ''}`}>
                            <Link

                                to="/blog"
                                title="Blog"
                                onClick={() => setSelectedMenuItem('Blog')}>
                                {menubar.blog}</Link></li> */}
                        {/* <li className="disabled-link"><a href="#" title="This is Not Working.">{menubar.blog}</a></li> */}
                        <li className={`${activeLink === 'Contact' ? highlightLink : ''} contact-btn`}>
                            <Link
                                onClick={() => { setSelectedMenuItem('Contact'); scrollToTop() }}
                                to="/contact"
                                title="Contact Us" >
                                {menubar.contactBtn}</Link></li>
                    </ul>
                </nav>
            </div>
            




            {isMenuOpen && (
        <ul className="main-menu">
          <li>
            <Link to="/" onClick={() => setIsMenuOpen(false)}>Home</Link>
          </li>

          <li>
            <Link to="/products" onClick={() => setIsMenuOpen(false)}>Products</Link>
          </li>

          <li className="menu-item-with-submenu">
            <button onClick={() => toggleSubMenu('company')}>Company</button>
            {activeSubMenu === 'company' && (
              <ul className="submenu">
                <li>
                  <Link to="/about" onClick={() => setIsMenuOpen(false)}>About Us</Link>
                </li>
                <li>
                  <Link to="/reviews" onClick={() => setIsMenuOpen(false)}>Testimonial</Link>
                </li>
                <li>
                  <Link to="/projects" onClick={() => setIsMenuOpen(false)}>Projects</Link>
                </li>
                <li>
                  <Link to="/core-team" onClick={() => setIsMenuOpen(false)}>Core Team</Link>
                </li>
              </ul>
            )}
          </li>

          <li className="menu-item-with-submenu">
            <button onClick={() => toggleSubMenu('services')}>Services</button>
            {activeSubMenu === 'services' && (
              <ul className="submenu">
                <li>
                  <Link to="/web-application-development" onClick={() => setIsMenuOpen(false)}>Website Development</Link>
                </li>
                <li>
                  <Link to="/mobile-application-development" onClick={() => setIsMenuOpen(false)}>App Development</Link>
                </li>
                <li>
                  <Link to="/mobile-application-testing" onClick={() => setIsMenuOpen(false)}>Mobile Testing</Link>
                </li>
                <li>
                  <Link to="/test-automation-services" onClick={() => setIsMenuOpen(false)}>Test Automation</Link>
                </li>
                <li>
                  <Link to="/manual-testing-services" onClick={() => setIsMenuOpen(false)}>Manual Testing</Link>
                </li>
                <li>
                  <Link to="/api-testing-services" onClick={() => setIsMenuOpen(false)}>API Testing</Link>
                </li>
                <li>
                  <Link to="/load-and-performance-testing" onClick={() => setIsMenuOpen(false)}>Load & Performance</Link>
                </li>
                <li>
                  <Link to="/security-testing-services" onClick={() => setIsMenuOpen(false)}>Security Testing</Link>
                </li>
                <li>
                  <Link to="/compatibility-testing-services" onClick={() => setIsMenuOpen(false)}>Compatibility Testing</Link>
                </li>
                <li>
                  <Link to="/unit-testing-services" onClick={() => setIsMenuOpen(false)}>Unit Testing</Link>
                </li>
                <li>
                  <Link to="/cross-browser-testing-services" onClick={() => setIsMenuOpen(false)}>Cross Browser Testing</Link>
                </li>
              </ul>
            )}
          </li>

          <li>
            <Link to="/qa-consultation" onClick={() => setIsMenuOpen(false)}>QA Consultation</Link>
          </li>

          <li>
            <Link to="/contact" onClick={() => setIsMenuOpen(false)}>Contact Us</Link>
          </li>
        </ul>
      )}







        </header>
    </>);
}

export default Header;